import React from "react"
import _map from "lodash/map"
import _forEach from "lodash/forEach"

const componentName = "&"

// const isClientAndDevMode = typeof window !== 'undefined' && window.location.hostname === 'localhost'

function between(value: string, start: string, end: string): Array<string> {
	const parts = value.split(end)
	return _map(parts, (text) => {
		return text.substr(text.indexOf(start) + start.length).trim()
	})
}

const transformSheet = function (
	stylesheet: string,
	namespace: string
): string {
	// space added after // to avoid false positives on http://
	if (stylesheet.indexOf("// ") !== -1) {
		console.error(
			`InlineCss(#${namespace}): Please use /* */ for comments instead of //`
		)
	}

	// Super basic validator. Could definitely
	// be improved, but will do so on a case
	// by case basis
	if (
		typeof window !== "undefined" &&
		window.location.hostname === "localhost" &&
		namespace !== "StripeToken" &&
		namespace !== "RootViewController"
	) {
		const matches = between(stylesheet, "}", "{")

		_forEach(matches, (match) => {
			// Guard
			if (
				match.indexOf("@media") !== -1 ||
				match.startsWith("@keyframes ") ||
				match.startsWith("0%") ||
				match.startsWith("100%") ||
				match.startsWith("@font-face")
			) {
				return
			} else if (
				match.indexOf("body") === 0 ||
				match.startsWith("header") ||
				match.startsWith(":root")
			) {
				return
			} else if (match.indexOf("input[type=range]") !== -1) {
				return
			} else if (match === "::-webkit-scrollbar") {
				return
			} else if (match.indexOf("::") !== -1 && match.indexOf(",") !== -1) {
				console.warn(
					"Firefox does not support this: ",
					match,
					". It is best to put them as separate definitions"
				)
			}
			const cleanedUp: string = match.split(" ").join("")

			// Guard
			if (
				cleanedUp.indexOf("&") === 0 ||
				cleanedUp.length === 0 ||
				cleanedUp.indexOf("}") === 0
			) {
				return
			}

			if (match.indexOf("&") === -1) {
				console.error(
					`InlineCss(#${namespace}) - invalid stylesheet syntax`
				)
				console.error(match)
			}
		})
	}

	return stylesheet
		.replace(/}\s*/gi, "\n}\n")
		.replace(
			/(^|{|}|;|,)\s*([&a-z0-9\-_\.:#\(\),>*\s]+)\s*(\{)/gi,
			function (matched) {
				return matched.replace(
					new RegExp(componentName, "g"),
					"#" + namespace
				)
			}
		)
}

export type Props = {
	ref?: void
	id: string
	children: any
	stylesheet: string
	className?: string
	onClick?: (e: any) => any
	onDoubleClick?: (e: any) => any
}

const InlineCss = React.forwardRef<HTMLDivElement, Props>(
	(props: Props, ref) => {
		const { id, className, onDoubleClick, onClick } = props

		const stylesheet = transformSheet(props.stylesheet, id)
		const styleElem = React.createElement("style", {
			dangerouslySetInnerHTML: { __html: stylesheet }
		})

		return (
			<div
				id={id}
				ref={ref}
				onClick={onClick}
				className={className}
				onDoubleClick={onDoubleClick}>
				{props.children != null ? props.children : []}
				{styleElem}
			</div>
		)
	}
)

InlineCss.displayName = "InlineCss"

export default InlineCss
