import { RouteMatch } from "@remix-run/react"
import { App } from "../types"

export function getActiveSegmentedMenu(props: {
	menu: App.StorefrontAPI.Menu
	matches: RouteMatch[]
}) {
	const { matches } = props
	const segments = props.menu.items
	let activeSegment: App.StorefrontAPI.MenuItem = segments[0]
	const pathname = matches[matches.length - 1].pathname

	if (pathname === "/") {
		activeSegment = segments[0]
	} else if (pathname === "/pages/herre") {
		const herreSegment = segments.find((segment) => {
			return segment.title.toLowerCase() === "herre"
		})
		activeSegment = herreSegment!
	} else if (pathname === "/pages/barn") {
		const barnSegment = segments.find((segment) => {
			return segment.title.toLowerCase() === "barn"
		})
		activeSegment = barnSegment!
	}

	segments.forEach((segment) => {
		matches.forEach((match) => {
			const data: {
				product?: {
					title: string
					tags: Array<string>
				}
				collection?: {
					title: string
					metafield: {
						value: string
					}
				}
				cart?: {
					attributes?: {
						_segment: string
					}
				}
			} = match.data

			if (data.product?.tags) {
				const tags = data.product.tags.map((tag) => tag.toLowerCase())
				if (tags.includes(segment.title.toLowerCase()) === true) {
					activeSegment = segment
				}
			}

			const collectionNavSegmentMetafield = data.collection?.metafield
			if (
				collectionNavSegmentMetafield?.value?.toLowerCase() ===
				segment.title.toLowerCase()
			) {
				const segment = segments.find((segment) => {
					return (
						segment.title.toLowerCase() ===
						data.collection?.metafield.value.toLowerCase()
					)
				})
				activeSegment = segment!
			}
		})
	})
	return activeSegment
}
