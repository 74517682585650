import { useNavigation } from "@remix-run/react"
import InlineCss from "./InlineCss"

const Loader = () => {
	const transition = useNavigation()
	const color1 = "#edb88b"
	const color2 = "#ef6f6c"

	if (transition.state !== "loading") {
		return null
	}

	return (
		<InlineCss
			id="AnimatedProgressBarForNav"
			stylesheet={`
				& {
					width: 100%;
					height: 12px;
					background: #e1e4e8;
					position: fixed;
					top: 0;
					left: 0;
					box-shadow: 0 0 0.2rem rgba(68, 109, 246, 1);
					width: 100%;
					/* this should be higher than slideout cart */
					z-index: 100000000000;
					transition: transform .1s cubic-bezier(.82,.02,.15,1.1);
				}

				& .progress-bar {
					display: block;
					height: 100%;
					background: linear-gradient(90deg, ${color1}, ${color2} 17%, ${color1} 34%, ${color2} 51%, ${color1} 68%, ${color2} 85%, ${color1});
					background-size: 300% 100%;
					animation: progress-animation 2s linear infinite;
				}

				@keyframes progress-animation {
					0% {
						background-position: 100%;
					}
					100% {
						background-position: 0;
					}
				}
			`}
			className="progress">
			<span
				className="progress-bar"
				style={{ width: "100%" }}
			/>
		</InlineCss>
	)
}

export default Loader
