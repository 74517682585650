import {
	defer,
	type LinksFunction,
	type MetaFunction,
	redirect
} from "@shopify/remix-oxygen"
import {
	Await,
	Links,
	Meta,
	Outlet,
	Scripts,
	ScrollRestoration,
	useLoaderData,
	useMatches
} from "@remix-run/react"
import { ShopifySalesChannel, Seo, type SeoHandleFunction } from "@shopify/hydrogen"

import tailwindCSS from "./styles/tailwind.css"
import mainStyles from "./styles/main.css"
import swiperStyles from "../node_modules/swiper/swiper.css"
import favicon from "../public/favicon.svg"

import { DEFAULT_LOCALE, flattenShopifyObject } from "./shared/utils"
import { useAnalytics } from "./hooks/useAnalytics"
import { App, ILoaderArgs } from "./types"
import { storefrontAPI } from "./shared/storefront-api/StorefrontAPI"
import { MenuRequest } from "../graphql/storefront-api"
import Loader from "./components/ui/Loader"
import { Suspense, cloneElement, useEffect } from "react"
import { getActiveSegmentedMenu } from "./shared/getActiveSegmentedMenu"
import { useGlobalStore } from "./shared/useGlobalStore"
import LocationService from "./components/location-picker/LocationService"
import WishListService from "./swym/WishListService"
import { getSettingsMetaObjects } from "./routes/($lang)/getSettingsMetaObjects"
import { CMS, isImageFieldField } from "./sections/CMS"
import { Layout } from "./components"
import CartSessionService from "./routes/api/cart-session/CartSessionService"
import { consoleTime, consoleDebug } from "./consoleTime"

const seo: SeoHandleFunction<typeof loader> = ({ data, pathname }) => ({
	title: data?.shop?.name,
	titleTemplate: "%s | Hydrogen Demo Store",
	// description shouldnt be more than 155 chars
	description: data?.shop?.description?.slice(0, 154),
	handle: "@shopify",
	url: `https://hydrogen.shop${pathname}`
})

export const handle = {
	seo
}
export const links: LinksFunction = () => {
	return [
		{ rel: "stylesheet", href: tailwindCSS },
		{ rel: "stylesheet", href: mainStyles },
		{ rel: "stylesheet", href: swiperStyles },
		{ rel: "preconnect", href: "https://cdn.shopify.com" },
		{ rel: "preconnect", href: "https://shop.app" }
		// { rel: "icon", type: "image/svg+xml", href: favicon }
	]
}

export const meta: MetaFunction = () => ({
	charset: "utf-8",
	viewport: "width=device-width,initial-scale=1"
})

const menuRequest: MenuRequest = {
	id: true,
	title: true,
	handle: true,
	items: {
		id: true,
		title: true,
		type: true,
		url: true,
		resourceId: true,
		items: {
			id: true,
			title: true,
			type: true,
			url: true,
			resourceId: true,
			items: {
				id: true,
				title: true,
				type: true,
				url: true,
				resourceId: true
			}
		}
	}
} as const

async function getOrCreateCart(cartId: null | string) {
	// const timer = consoleTime("getCart: " + cartId)
	const cart =
		cartId == null
			? await storefrontAPI.createCart()
			: await storefrontAPI.fetchCart({ cartId: cartId })
	// timer.end()

	return flattenShopifyObject(cart)
}

export async function loader({ context, params, request }: ILoaderArgs) {
	const timer = consoleTime("Root loader" + request.url)
	const _cartId = await context.session.get("cartId")

	// lang is the optional ($lang) parent route
	const lang: undefined | string = params.lang
	// empty pathPrefix means there is no url suffix defined for the selected market
	// so if lang is defined, it means we should redirect the user
	if (lang != null && context.storefront.i18n.pathPrefix === "") {
		throw redirect(request.url.replace(`/${lang}/`, "/"))
	}

	const cachedRes = await context.cache.getCachedResponse(
		new URL(`${request.url}layout-data-root`)
	)

	const getLayoutAndSettingsData = () =>
		Promise.all([getSettingsMetaObjects(), getLayoutData(context)])

	const layoutAndSettingsData =
		cachedRes?.response != null
			? await cachedRes?.response
					.json()
					.then(
						(res: any) =>
							res as Awaited<ReturnType<typeof getLayoutAndSettingsData>>
					)
			: await getLayoutAndSettingsData()

	if (cachedRes != null && cachedRes.response == null) {
		await cachedRes.setCacheResponse(
			new Response(JSON.stringify(layoutAndSettingsData), {
				headers: {
					Expires: new Date(Date.now() + 86400).toUTCString(),
					"Content-Type": "application/json",
					"Cache-Control": "public, max-age=86400"
				}
			})
		)
	}

	const [settings, layout] = layoutAndSettingsData

	const headerLogos: CMS.ImageFileField[] = []
	settings.fields.forEach((field) => {
		if (!isImageFieldField(field)) {
			return
		}

		if (
			field.key === "logo_women" ||
			field.key === "logo_men" ||
			field.key === "logo_kids"
		) {
			headerLogos.push(field)
		}
	})

	const footerLogo =
		settings.fields.find((field) => {
			return field.key === "footer_logo"
		}) || null

	const data: App.Loader.Route.Root = {
		layout,
		shop: layout.shop,
		selectedLocale: context.storefront.i18n,
		cart: getOrCreateCart(_cartId || null),
		analytics: {
			shopifySalesChannel: ShopifySalesChannel.hydrogen,
			shopId: layout.shop.id
		},
		localization: context.localization,
		headerLogos: headerLogos,
		footerLogo: footerLogo as CMS.ImageFileField | null
	}

	timer.end()
	return defer(data, {
		headers: {
			"Set-Cookie": await context.session.commit()
		}
	})
}

export default function HydrogenApp() {
	const data = useLoaderData<typeof loader>()
	const locale = data.selectedLocale ?? DEFAULT_LOCALE
	const hasUserConsent = true
	useAnalytics(hasUserConsent, locale)

	const matches = useMatches()

	const renderIntoHeadMatches: Array<JSX.Element> = []
	matches.forEach((match) => {
		const renderIntoHead = match.handle?.renderIntoHead
		if (Array.isArray(renderIntoHead)) {
			renderIntoHead.forEach((elem) => {
				renderIntoHeadMatches.push(elem)
			})
		}
	})

	const activeSegmentedMenu = getActiveSegmentedMenu({
		matches: matches,
		menu: data.layout.headerMenu!
	})

	useEffect(() => {
		LocationService.init()
		WishListService.init()
	}, [])

	return (
		<html lang={locale.language}>
			<head>
				<Seo />
				<Meta />
				<Links />
				{renderIntoHeadMatches.map((elem, i) => {
					return cloneElement(elem, { key: i })
				})}
				<style
					dangerouslySetInnerHTML={{
						__html: `
						@font-face {
							font-family: "Century Gothic Bold";
							src: url("/fonts/CenturyGothic-bold.ttf");
						}

						@font-face {
							font-family: "Century Gothic";
							src: url("/fonts/CenturyGothic.ttf");
						}
						`
					}}
				/>
			</head>
			<body>
				<Loader />
				<Layout
					footerLogo={data.footerLogo}
					headerLogos={data.headerLogos}
					activeSegmentedMenu={activeSegmentedMenu}
					mobileMenu={data.layout.mobileMenu}
					isLoggedIn={false}
					mainMenu={data.layout.headerMenu}
					shop={data.shop}
					footerMenu={data.layout.footerMenu}
					key={`${locale.language}-${locale.country}`}>
					<Outlet />
				</Layout>
				<ScrollRestoration />
				<Scripts />

				<Suspense>
					<Await resolve={data.cart}>
						{(cart) => {
							if (typeof window === "undefined") {
								return null
							}
							if (cart != null) {
								// setTimeout seems to be needed to avoid a render clash
								setTimeout(() => {
									useGlobalStore.setState({ cart })
								}, 10)
								CartSessionService.setcartIdInSession({
									cartId: cart.id
								})
							}
							return null
						}}
					</Await>
				</Suspense>

				{/* <script src="https://a.klaviyo.com/media/js/onsite/onsite.js" />
				<script
					dangerouslySetInnerHTML={{
						__html: `
						var klaviyo = klaviyo || [];
						klaviyo.init({
							account: 'YcYCaG',
							platform: 'shopify'
						})
						klaviyo.enable('backinstock', {
							trigger: {
								product_page_text: 'Motta varsel ved påfyll',
								product_page_class: 'button',
								product_page_text_align: 'center',
								product_page_margin: '0px',
								replace_anchor: false
							},
							modal: {
								headline: '{product_name}',
								body_content:
									'Meld deg på under her for å motta varsel på mail hvis varen kommer på lager igjen',
								email_field_label: 'E-post',
								button_label: 'Varsle meg hvis den kommer på lager',
								subscription_success_label:
									'Nå er du registrert! Hvis den varen kommer inn på lager, sender vi deg en mail.',
								footer_content: '',
								additional_styles:
									"@import url('https://fonts.googleapis.com/css?family=Century+Gothic');",
								drop_background_color: '#000',
								background_color: '#fff',
								text_color: '#1c1d1d',
								button_text_color: '#fff',
								button_background_color: '#e85f5f',
								close_button_color: '#ccc',
								error_background_color: '#fcd6d7',
								error_text_color: '#C72E2F',
								success_background_color: '#d3efcd',
								success_text_color: '#1B9500'
							}
						})`
					}}
				/> */}
			</body>
		</html>
	)
}

async function getLayoutData({ storefront }: ILoaderArgs["context"]) {
	// TODO do segmented menu
	const HEADER_MENU_HANDLE = "fd-theme-master-menu-desktop"
	const MOBILE_MENU_HANDLE = "fd-theme-master-menu-mobile"
	const FOOTER_MENU_HANDLE = "fd-footer-menu"

	const [headerMenu, footerMenu, mobileMenu, shop] = await Promise.all([
		storefrontAPI
			.query({
				menu: [
					{
						handle: HEADER_MENU_HANDLE
					},
					menuRequest
				]
			})
			.then((res) => {
				return res.data?.menu!
			}),
		storefrontAPI
			.query({
				menu: [
					{
						handle: FOOTER_MENU_HANDLE
					},
					menuRequest
				]
			})
			.then((res) => {
				return res.data?.menu!
			}),
		storefrontAPI
			.query({
				menu: [
					{
						handle: MOBILE_MENU_HANDLE
					},
					menuRequest
				]
			})
			.then((res) => {
				return res.data!.menu!
			}),
		storefrontAPI
			.query({
				shop: {
					name: true,
					description: true,
					id: true,
					moneyFormat: true,
					paymentSettings: {
						acceptedCardBrands: true,
						supportedDigitalWallets: true
					},
					primaryDomain: {
						url: true,
						host: true
					}
				}
			})
			.then((res) => {
				return res.data?.shop!
			})
	])
	return { shop, headerMenu, mobileMenu, footerMenu }
}

// TODO
// let requests = 0
// const parseMenu = async (args: { menu: App.StorefrontAPI.MenuItem }) => {
// 	if (args.menu.items == null) {
// 		return
// 	}
// 	for (const item of args.menu.items) {
// 		let newItem: App.StorefrontAPI.MenuItemItem & {
// 			featruedImage: null | string
// 		} = {
// 			...item,
// 			featruedImage: null
// 		}
// 		if (item.type === "COLLECTION") {
// 			if (item.resourceId == null || requests > 40) {
// 				continue
// 			}
// 			requests++
// 			const featuredImage = await storefrontAPI
// 				.query({
// 					collection: [
// 						{
// 							id: item.resourceId
// 						},
// 						{
// 							image: {
// 								__scalar: true
// 							}
// 						}
// 					]
// 				})
// 				.then((res) => {
// 					invariant(res.data?.collection)
// 					return res.data.collection.image
// 				})
// 			item.featuredImage = featuredImage != null ? featuredImage.url : null
// 		}
// 		await parseMenu({ menu: item })
// 	}
// }
// await parseMenu({ menu: data.mobileMenu })
